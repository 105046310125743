import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './ProductosData.css'
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import baseURL from '../../url';
import NewProduct from '../NewProduct/NewProduct';
export default function ProductosData() {
    const [nuevaVariante, setNuevaVariante] = useState({
        tipo: '',
        nombre: '',
        precio_variante: '',
        precio_suma: '',
        multiple: false
    });
    const fileInputRef = useRef(null);
    const [productos, setProductos] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoTitulo, setNuevoTitulo] = useState('');
    const [nuevaDescripcion, setNuevaDescripcion] = useState('');
    const [nuevoPrecio, setNuevoPrecio] = useState('');
    const [nuevoStock, setNuevoStock] = useState('');
    const [nuevoEstado, setNuevoEstado] = useState(null);
    const [nuevoPrecioAnterior, setNuevoPrecioAnterior] = useState(0);
    const [nuevaCategoria, setNuevaCategoria] = useState('');
    const [producto, setProducto] = useState({});
    const [modalImagenVisible, setModalImagenVisible] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroTitulo, setFiltroTitulo] = useState('');
    const [filtroCategoria, setFiltroCategoria] = useState('');
    const [filtroCategoria2, setFiltroCategoria2] = useState('');
    const [filtroMasVendido, setFiltroMasVendido] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [selectedSection, setSelectedSection] = useState('texto');
    const [nuevoMasVendido, setNuevoMasVendido] = useState('');
    const [categorias, setCategoras] = useState([]);
    const [variantes, setVariantes] = useState([]);
    const [nuevaImagen, setNuevaImagen] = useState(null);
    const [imagenes, setImagenes] = useState([]);
    const [imagenesEditadas, setImagenesEditadas] = useState([]);

    const cerrarModalImagen = () => {
        setModalImagenVisible(false);
    };



    useEffect(() => {
        cargarProductos();

    }, []);

    useEffect(() => {
        // Actualiza el valor del select cuando cambia el estado nuevoEstado
        setNuevoTitulo(producto.titulo);
        setNuevaDescripcion(producto.descripcion);
        setNuevoPrecio(producto.precio);
        setNuevoStock(producto.stock);
        setNuevoEstado(producto.estado);
        setNuevoMasVendido(producto.masVendido)
        setNuevaCategoria(producto.categoria)
        setNuevoPrecioAnterior(producto.precioAnterior);
    }, [producto]);

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
                console.log(data.productos)
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const eliminarProducto = (idProducto) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/productDelete.php?idProducto=${idProducto}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarProductos();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };


    const handleCheckboxChange = (tipo, property) => {
        setVariantes(prevVariantes => ({
            ...prevVariantes,
            [tipo]: {
                ...prevVariantes[tipo],
                [property]: !prevVariantes[tipo][property]
            }
        }));
    };

    const abrirModal = (item) => {
        setProducto(item);
        setNuevoTitulo(item.titulo);
        setVariantes(obtenerVariantes(item));
        setNuevaDescripcion(item.descripcion);
        setImagenes(obtenerImagenes(item));
        setNuevoPrecio(item.precio);
        setNuevoStock(item.stock);
        setNuevoEstado(item.estado);
        setModalVisible(true);
        setImagenesEditadas([]);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const productosFiltrados = productos.filter(item => {
        const idMatch = item.idProducto.toString().includes(filtroId);
        const tituloMatch = !filtroTitulo || item.titulo.includes(filtroTitulo);
        const categoriaMatch = !filtroCategoria || item.categoria.includes(filtroCategoria);
        const masVendidoMatch = !filtroMasVendido || item.masVendido.includes(filtroMasVendido);
        const categoriasMatch = !filtroCategoria2 || item.categoria.includes(filtroCategoria2);
        return idMatch && tituloMatch && categoriaMatch && masVendidoMatch && categoriasMatch;
    });

    const descargarExcel = () => {
        const data = productosFiltrados.map(item => ({
            IdProducto: item.idProducto,
            Titulo: item.titulo,
            Descripcion: item.descripcion,
            Precio: item.precio,
            Fecha: item.createdAt,
            MasVendido: item.masVendido

        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Productos');
        XLSX.writeFile(wb, 'productos.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        pdf.text('Lista de Productos', 10, 10);

        const columns = [ 
            { title: 'Nombre', dataKey: 'titulo' },
            { title: 'Descripcion', dataKey: 'descripcion' },
            { title: 'Precio', dataKey: 'precio' },
            { title: 'MasVendido', dataKey: 'masVendido' },
            { title: 'Fecha', dataKey: 'createdAt' },
        ];

        const data = productosFiltrados.map(item => ({ 
            Titulo: item.titulo,
            Descripcion: item.descripcion,
            Precio: item.precio,
            MasVendido: item.masVendido,
            Fecha: item.createdAt,

        }));

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('productos.pdf');
    };

    const recargarProductos = () => {
        cargarProductos();
    };
    const invertirOrden = () => {
        setProductos([...productos].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const obtenerImagenes = (item) => {
        let imagenesItem;
        try {
            imagenesItem = JSON.parse(item.imagenes);
        } catch (e) {
            console.error("Error parsing 'imagenes':", e);
            return [];
        }

        if (imagenesItem && imagenesItem.urls) {
            return imagenesItem.urls;
        }
        return [];
    };


    const obtenerVariantes = (item) => {
        let variantes;
        try {
            variantes = JSON.parse(item.variantes);
        } catch (e) {
            console.error("Error parsing 'variantes':", e);
            return {};
        }

        if (variantes && variantes.tipos) {
            Object.keys(variantes.tipos).forEach(tipo => {
                if (!Array.isArray(variantes.tipos[tipo])) {
                    // Si ya está en el nuevo formato, asegúrate de que tenga todas las propiedades
                    variantes.tipos[tipo] = {
                        multiple: variantes.tipos[tipo].multiple || false,
                        obligatorio: variantes.tipos[tipo].obligatorio || false,
                        variantes: variantes.tipos[tipo].variantes || []
                    };
                } else {
                    // Convertir al nuevo formato
                    variantes.tipos[tipo] = {
                        multiple: false,
                        obligatorio: false,
                        variantes: variantes.tipos[tipo]
                    };
                }
            });
            return variantes.tipos;
        }
        return {};
    };


    const agregarVariante = (item) => {
        setVariantes(prevVariantes => {
            const newVariantes = { ...prevVariantes };
            if (!newVariantes[nuevaVariante.tipo]) {
                newVariantes[nuevaVariante.tipo] = {
                    multiple: false,
                    obligatorio: false,
                    variantes: []
                };
            }
            newVariantes[nuevaVariante.tipo].variantes.push({
                nombre: nuevaVariante.nombre,
                precio_variante: nuevaVariante.precio_variante || 0,
                precio_suma: nuevaVariante.precio_suma || 0
            });
            return newVariantes;
        });

        setNuevaVariante({ tipo: '', nombre: '', precio_variante: '', precio_suma: '' });
    };
    const borrarImagen = (index) => {
        let imgs = obtenerImagenes(producto);

        // Verifica que el índice sea válido
        if (index >= 0 && index < imgs.length) {
            // Crea una nueva copia del array sin el elemento en el índice especificado
            const updatedImgs = [...imgs];
            updatedImgs.splice(index, 1);

            let newData = { urls: updatedImgs };
            console.log("Imágenes después de eliminar:", updatedImgs);
            setImagenes(updatedImgs);
            setProducto({
                ...producto,
                imagenes: JSON.stringify(newData)
            })
        } else {
            console.log("Índice inválido:", index);
        }
    };

    const borrarImagenNueva = (index) => {
        let imgs = imagenesEditadas;

        // Verifica que el índice sea válido
        if (index >= 0 && index < imgs.length) {
            // Crea una nueva copia del array sin el elemento en el índice especificado
            const updatedImgs = [...imgs];
            updatedImgs.splice(index, 1);
            setImagenesEditadas(updatedImgs);
        } else {
            console.log("Índice inválido:", index);
        }
    };
    const eliminarVariante = (tipo, nombre) => {
        setVariantes(prevVariantes => {
            const newVariantes = { ...prevVariantes };
            if (newVariantes[tipo]) {
                newVariantes[tipo].variantes = newVariantes[tipo].variantes.filter(variante => variante.nombre !== nombre);
                
                // Eliminar el tipo si no quedan variantes
                if (newVariantes[tipo].variantes.length === 0) {
                    delete newVariantes[tipo];
                }
            }
            return newVariantes;
        });
    };



    const handleUpdateText = (idProducto) => {
        let variantesData = { tipos: {} };
        Object.keys(variantes).forEach(tipo => {
            variantesData.tipos[tipo] = {
                multiple: variantes[tipo].multiple,
                obligatorio: variantes[tipo].obligatorio,
                variantes: variantes[tipo].variantes
            };
        });
        const payload = {

            nuevoTitulo: nuevoTitulo !== '' ? nuevoTitulo : producto.titulo,
            nuevaDescripcion: nuevaDescripcion !== '' ? nuevaDescripcion : producto.descripcion,
            nuevoPrecio: nuevoPrecio !== '' ? nuevoPrecio : producto.precio,
            nuevaCategoria: nuevaCategoria !== '' ? nuevaCategoria : producto.categoria,
            masVendido: nuevoMasVendido !== '' ? nuevoMasVendido : producto.masVendido,
            precioAnterior: nuevoPrecioAnterior !== 0 ? nuevoPrecioAnterior : producto.precioAnterior,
            variantes: JSON.stringify(variantesData),
            nuevoEstado: nuevoEstado !== 0 ? nuevoEstado : producto.estado,
            nuevoStock: nuevoStock !== 0 ? nuevoStock : producto.stock,
            
        };

        fetch(`${baseURL}/productoTextPut.php?idProducto=${idProducto}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {

                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {

                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarProductos();
                    cerrarModal()
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };


    const handleImagenChange = (event) => {
        const files = event.target.files;
        const newFiles = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const previewURL = URL.createObjectURL(file);
            newFiles.push({ file, previewURL });

        }
        setImagenesEditadas(newFiles);
        console.log("nuevas imagenes", newFiles);

        // Resetear el input de tipo file
        event.target.value = '';

    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleEditarImagenes = (idProducto) => {

        const formData = new FormData();
        formData.append('idProducto', idProducto);
        formData.append('updateAction', 'update'); // Campo adicional para indicar que es una actualización
        imagenesEditadas.forEach((imagen, index) => {
            formData.append(`imagenes[]`, imagen.file);
        });

        formData.append('imagenes_old', JSON.stringify(imagenes));
        fetch(`${baseURL}/productoImagePut.php`, {
            method: 'POST',  // Cambiado a POST
            body: formData
        })
            .then(response => {
                // Manejar el caso cuando la respuesta no es un JSON válido o está vacía
                if (!response.ok) {
                    throw new Error('La solicitud no fue exitosa');

                }

                return response.json();
            })
            .then(data => {
                if (data.error) {

                    toast.error(data.error);
                    console.log(formData)
                } else {

                    toast.success(data.mensaje);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message);
                console.log(formData)
                console.log(idProducto)
            });
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    useEffect(() => {
        cargarCategoria();

    }, []);


    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategoras(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    return (
        <div>

            <ToastContainer />
            <div className='deFlexContent'>

                <div className='deFlex2'>
                    <NewProduct />
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>
                <div className='filtrosContain'> 

                    <div className='inputsColumn'>
                        <input type="text" value={filtroTitulo} onChange={(e) => setFiltroTitulo(e.target.value)} placeholder='Nombre' />
                    </div>
                    <div className='inputsColumn'>
                        <input type="text" value={filtroCategoria2} onChange={(e) => setFiltroCategoria2(e.target.value)} placeholder='Categoria' />
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroCategoria} onChange={(e) => setFiltroCategoria(e.target.value)}>
                            <option value="">Categorías</option>
                            {
                                categorias.map((item, index) => (
                                    <option key={index} value={item?.categoria}>{item?.categoria}</option>
                                ))
                            }

                        </select>
                    </div>
                    <div className='inputsColumn'>
                        <select value={filtroMasVendido} onChange={(e) => setFiltroMasVendido(e.target.value)}>
                            <option value="">Más vendidos</option>
                            <option value="si">Si</option>
                            <option value="no">No</option>

                        </select>
                    </div>

                    <button className='reload' onClick={recargarProductos}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>


            {modalImagenVisible && (
                <div className="modalImg">
                    <div className="modal-contentImg">


                        <span className="close2" onClick={cerrarModalImagen}>
                            &times;
                        </span>

                        <img src={imagenSeleccionada} alt="Imagen Seleccionada" />
                    </div>
                </div>
            )}

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                                <button
                                    className={selectedSection === 'imagenes' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('imagenes')}
                                >
                                    Editar Imagenes
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nuevoTitulo !== '' ? nuevoTitulo : producto.titulo}
                                        onChange={(e) => setNuevoTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Precio</legend>
                                    <input
                                        type="number"
                                        value={nuevoPrecio !== '' ? nuevoPrecio : producto.precio}
                                        onChange={(e) => setNuevoPrecio(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Stock</legend>
                                    <input
                                        type="number"
                                        value={nuevoStock !== '' ? nuevoStock : producto.titulo}
                                        onChange={(e) => setNuevoStock(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        value={nuevoEstado !== '' ? nuevoEstado : producto.estado}
                                        onChange={(e) => setNuevoEstado(e.target.value)}
                                    >

                                        <option value="0">Desactivado</option> 
                                        <option value="1">Activado</option>
                                    </select>
                                </fieldset>
                                <fieldset id='descripcion'>
                                    <legend>Descripcion</legend>
                                    <textarea
                                        type="text"
                                        value={nuevaDescripcion !== '' ? nuevaDescripcion : producto.descripcion}
                                        onChange={(e) => setNuevaDescripcion(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Categoria</legend>
                                    <select
                                        value={nuevaCategoria !== '' ? nuevaCategoria : producto.categoria}
                                        onChange={(e) => setNuevaCategoria(e.target.value)}
                                    >
                                        {
                                            categorias.map((item, index) => (
                                                <option key={index} value={item?.categoria}>{item?.categoria}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Más vendido</legend>
                                    <select
                                        value={nuevoMasVendido !== '' ? nuevoMasVendido : producto.masVendido}
                                        onChange={(e) => setNuevoMasVendido(e.target.value)}
                                    >

                                        <option value="no">No</option> 
                                        <option value="si">Si</option>
                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Precio anterior</legend>
                                    <input
                                        type="number"
                                        value={nuevoPrecioAnterior !== '' ? nuevoPrecioAnterior : producto.precioAnterior}
                                        onChange={(e) => setNuevoPrecioAnterior(e.target.value)}
                                    />
                                </fieldset>

                            </div>
                            <div className='flexGrap'>
                                <legend>Variables</legend>

                                <div className='table-container'>
                                    <table className='table table-border'>
                                        <thead>
                                            <tr>
                                                <th> Tipo </th>
                                                <th> nombre </th>
                                                <th> $ Nuevo Precio </th>
                                                <th> $ Suma al precio </th>
                                                <th> Acciones </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(variantes).map((tipo) => (
                                                variantes[tipo].variantes.map((variante, varianteIndex) => (
                                                    <tr key={`${tipo}-${varianteIndex}`}>
                                                        <td>{tipo}</td>
                                                        <td>{variante.nombre}</td>
                                                        <td>{variante.precio_variante}</td>
                                                        <td>{variante.precio_suma}</td>
                                                        <td>
                                                            <button className="btnDelete" onClick={() => eliminarVariante(tipo, variante.nombre)}>
                                                                Eliminar <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ))}
                                            <tr>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={nuevaVariante.tipo}
                                                        onChange={(e) => setNuevaVariante({ ...nuevaVariante, tipo: e.target.value })}
                                                        placeholder="Tipo"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={nuevaVariante.nombre}
                                                        onChange={(e) => setNuevaVariante({ ...nuevaVariante, nombre: e.target.value })}
                                                        placeholder="Nombre"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={nuevaVariante.precio_variante}
                                                        onChange={(e) => setNuevaVariante({ ...nuevaVariante, precio_variante: e.target.value })}
                                                        placeholder="$ Nuevo Precio"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={nuevaVariante.precio_suma}
                                                        onChange={(e) => setNuevaVariante({ ...nuevaVariante, precio_suma: e.target.value })}
                                                        placeholder="$ Suma al Precio"
                                                    />
                                                </td>
                                                <td>
                                                    <button className="btnAction" onClick={() => agregarVariante(producto)}>
                                                        Agregar <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <hr />
                                    <p>Multiple es para poder ej: elegir ingredientes extra y sumar costo extra al precio,
                                        dar opciones de sabor o color etc cuando NO es multiple solo se puede eligir un tipo. Al ser multiple se deshabilita el reemplazo de precio solo se suman valor al precio.</p>

                                    {Object.keys(variantes).map((tipo, tipoIndex) => {
                                    let number = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                                    const uniqueIdMultiple = `switch-multiple-${number}`;
                                    const uniqueIdObligatorio = `switch-obligatorio-${number}`;
                                        return (
                                            <>
                                            
                                            <fieldset key={tipoIndex}>
                                                <legend>{tipo}</legend>
                                                <div >
                                                    <input
                                                        type="checkbox"
                                                        checked={variantes[tipo].multiple || false}
                                                    onChange={() => handleCheckboxChange(tipo, 'multiple')}
                                                    id={uniqueIdMultiple}
                                                    />
                                                    <label htmlFor={uniqueIdMultiple} ></label>
                                                    Multiple
                                                </div> 


                                                <div >
                                                    <input
                                                        type="checkbox"
                                                        checked={variantes[tipo].obligatorio || false}
                                                    onChange={() => handleCheckboxChange(tipo, 'obligatorio')}
                                                    id={uniqueIdObligatorio+'obl'}

                                                    />
                                                    <label htmlFor={uniqueIdObligatorio+'obl'} ></label>
                                                    obligatorio
                                                </div> 
                                            </fieldset>


                                             
                                            
                                            </>
                                        );
                                    })}


                                </div>
                            </div>




                            <button className='btnPost' onClick={() => handleUpdateText(producto.idProducto)} >Guardar </button>

                        </div>

                        <div className='sectionImg' style={{ display: selectedSection === 'imagenes' ? 'flex' : 'none' }}>
                            <div className='previevProduct'>

                                {imagenes.map((url, index) => (
                                    <div key={index}>
                                        <img src={url} alt={`Imagen ${index + 1}`} />
                                        <button className='delete' onClick={() => borrarImagen(index)}>eliminar <FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                ))}



                                {imagenesEditadas.map((file, index) => (
                                    <div key={index}>
                                        <img src={file.previewURL} alt={`Imagen ${index + 1}`} />
                                        <button className='delete' onClick={() => borrarImagenNueva(index)}>eliminar <FontAwesomeIcon icon={faTrash} /></button>
                                    </div>
                                ))}



                            </div><fieldset>
                                <legend>Agregar media </legend>

                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    id="imagenes"
                                    name="imagenes"
                                    accept="image/*"
                                    multiple // Permitir selección múltiple
                                    onChange={handleImagenChange}
                                    required
                                    style={{ display: 'none' }} // Ocultar el input original
                                />
                                <button type="button" onClick={handleButtonClick} className='btnAction'>
                                    Elegir imágenes
                                </button>
                            </fieldset>
                            <button className='btnPost' onClick={() => handleEditarImagenes(producto.idProducto)}>Guardar </button>
                        </div>

                    </div>



                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Estado</th>
                            <th>Stock</th>
                            <th>Precio</th>
                            <th>Categoria</th>
                            <th>Más vendido</th>
                            <th>Medios</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productosFiltrados.map(item => (
                            <tr key={item.idProducto}>
                                <td>{item.titulo}</td>
                                <td>{item.estado ? 'activo' : 'desactivado'}</td>

                                <td >
                                    {`${item?.stock}`}
                                </td>

                                <td >
                                    $ {`${item?.precio}`}
                                </td>

                                <td >  {`${item?.categoria}`}</td>
                                <td>{item.masVendido}</td>
                                <td>
                                    {obtenerImagenes(item).map((url, index) => (
                                        <img key={index} src={url} alt={`Imagen ${index + 1}`} />
                                    ))}
                                </td>

                                <td>

                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>

                                    <button className='eliminar' onClick={() => eliminarProducto(item.idProducto)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};