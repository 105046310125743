import React, { createContext, useState, useContext } from 'react';

// Crear el contexto
const SearchContext = createContext();

// Proveedor del contexto
export const SearchProvider = ({ children }) => {
    const [search, setSearch] = useState(JSON.parse(localStorage.getItem('search')) || false);

    // Actualizar el carrito tanto en el estado como en el localStorage
    const actualizarSearch = (newSearch) => {
        setSearch(newSearch);
        localStorage.setItem('search', JSON.stringify(newSearch));
    };

    return (
        <SearchContext.Provider value={{ search, actualizarSearch}}>
            {children}
        </SearchContext.Provider>
    );
};

// Hook personalizado para usar el contexto
export const useSearch2= () => useContext(SearchContext);
