import React, { useState, useEffect } from 'react';
import './NavbarMobile.css';
import { Link as Anchor, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faHome } from '@fortawesome/free-solid-svg-icons';
import BtnWhatsapp from '../BtnWhatsapp/BtnWhatsapp'
import Cart from '../Cart/Cart'
import Favoritos from '../Favoritos/Favoritos'
import InputSerach from '../InputSearch/InputSearchs'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import baseURL from '../url';
import useConfigStore from '../configStore';
 

export default function NavbarMobile({actualizarSearch = ()=>{}, searchTerm, setSearchTerm = () => { },  setProductoSeleccionado= () => {},  setModalIsOpen=()=>{}}) { 
    const [carrito, setCarrito] = useState(false);
    const config = useConfigStore((state) => state.configData);
    // Obtener configuración inicial
    const obtenerConfiguracion = async () => { 
            const data = config;

            if (data) {
                setCarrito((data.carrito == '1' ? true : false));
            }
         
    };

    useEffect(() => {

        obtenerConfiguracion();
    }, [config]);
    const location = useLocation(); 

 

    // funcion swalgames que lanza una ventana de aviso pronto
    const swalGames = () => {
        Swal.fire({
            title: 'Próximamente',
            text: '¡Estamos trabajando en ello! Pronto podrás explorar nuestra sección de juegos.',
            icon: 'info',
            confirmButtonText: 'Aceptar'
        })
    }
    // refresh locaion page
    const navigate = useNavigate()
    const refreshPage = () => {
        window.scrollTo(0, 0);
    }

    return ( 


            <section className='scrolledMobile'>
                <Anchor to={`/#`} className={location.pathname === '/#' ? '' : ''} onClick={refreshPage}  >
                    <FontAwesomeIcon icon={faHome} />
                    <strong>Inicio</strong>
                </Anchor>

                {
                    carrito ? (<Cart />) : (
                        <Anchor to={`/`} className={location.pathname === '/#games' ? '' : ''} onClick={swalGames}  >
                            <FontAwesomeIcon icon={faGamepad} />
                            <strong>Juegos</strong>

                        </Anchor>
                    )
                }

                <BtnWhatsapp />

                <Favoritos />
                <InputSerach  searchTerm={searchTerm} setSearchTerm={setSearchTerm}  setProductoSeleccionado={setProductoSeleccionado} setModalIsOpenProducto={setModalIsOpen}/> 


            </section> 


    );
}
