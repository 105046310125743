import React, { useState } from 'react';
import Login from '../Login/Login';
import Logo from '../../logo';
import './Auth.css'; 
import { Link as Anchor } from 'react-router-dom';
export default function Auth() {
    const [showLogin, setShowLogin] = useState(true);

    const toggleComponent = () => {
        setShowLogin((prevShowLogin) => !prevShowLogin);
    };

    return (
        <div className='AuthContainer'>
            <Anchor to={`/`} >
                 <Logo />
            </Anchor>

            <Login />


        </div>
    );
}
