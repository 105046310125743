import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header/Header';
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { toast, ToastContainer } from 'react-toastify';
import baseURL from '../../Components/url';

import Swal from 'sweetalert2';
export default function Colores() {
    const [defaultColors, setDefaultColors] = useState({});
    const [colors, setColors] = useState({});
    const [temas, setTemas] = useState([]);
    const [themaActivo, setThemaActivo] = useState('');
    const [themeName, setThemeName] = useState('');
    const iframeRef = useRef(null);
    const [temaSeleccionado, setTemaSeleccionado] = useState(0);
    const [avanzado, setAvanzado] = useState(false);

    useEffect(() => {
        const root = document.documentElement;
        const computedStyle = getComputedStyle(root);
        const colores = {
            textColor: computedStyle.getPropertyValue('--textColor').trim(),
            textColor2: computedStyle.getPropertyValue('--textColor2').trim(),
            textColor3: computedStyle.getPropertyValue('--textColor3').trim(),
            textTarjetaProductos: computedStyle.getPropertyValue('--textTarjetaProductos').trim(),
            textColor5: computedStyle.getPropertyValue('--textColor5').trim(),
            bgDestacado: computedStyle.getPropertyValue('--bgDestacado').trim(),
            textDestacado: computedStyle.getPropertyValue('--textDestacado').trim(),
            bgColor: computedStyle.getPropertyValue('--bgColor').trim(),
            gris: computedStyle.getPropertyValue('--gris').trim(),
            color1: computedStyle.getPropertyValue('--color1').trim(),
            color2: computedStyle.getPropertyValue('--color2').trim(),
            color3: computedStyle.getPropertyValue('--color3').trim(),
            colorNavegacion: computedStyle.getPropertyValue('--colorNavegacion').trim(),
            input: computedStyle.getPropertyValue('--input').trim(),
            loading: computedStyle.getPropertyValue('--loading').trim(),
            textFooter: computedStyle.getPropertyValue('--textFooter').trim(),
            colorTextoBtns: computedStyle.getPropertyValue('--colorTextoBtns').trim()
        };
        setDefaultColors(colores);

        const savedColors = localStorage.getItem('colors');
        if (savedColors) {
            setColors(JSON.parse(savedColors));
        } else {
            setColors(colores);
        }
    }, []);

    const cargarTemas = () => {
        fetch(`${baseURL}/temasGet.php`, { method: 'GET' })
            .then(response => response.json())
            .then(data => setTemas(data))
            .catch(error => console.error('Error al cargar temas:', error));
    };

    useEffect(() => {
        if (Object.keys(colors).length > 0) {
            localStorage.setItem('colors', JSON.stringify(colors));
        }
        cargarTemas();
    }, [colors]);

    const handleColorChange = (e) => {
        const { id, value } = e.target;
        setColors(prevColors => ({
            ...prevColors,
            [id]: value
        }));  
        

        console.log(value);
    };

    const resetColors = () => {
        setColors(defaultColors);
        setTemaSeleccionado(0);
        localStorage.removeItem('colors');
    };
const obtenerConfiguracion = async () => {
            try {
                const response = await fetch(`${baseURL}/configuracionesGet.php`);
                const data = await response.json();
                if (data) {
                    setThemaActivo(data.tema ? data.tema : 'default');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error al obtener la configuración. Por favor, inténtelo de nuevo.');
            }
        };
    useEffect(() => {
        
        obtenerConfiguracion();
    }, []);

    const captureIframe = async () => {
        const iframe = iframeRef.current;
        const iframeContent = iframe.contentDocument || iframe.contentWindow.document;
        const canvas = await html2canvas(iframeContent.body);
        return canvas.toDataURL('image/png');
    };

    const handleSaveColors = async () => {
        try {
            if (themeName === '') {
                alert('No haz nombrado el tema.');
                return;
            }
            const previewImage = await captureIframe();
            const response = await axios.post(`${baseURL}/temaPost.php`, {
                name: themeName,
                colors: colors,
                previewImage: previewImage
            });
            if (response.data.success) {
                toast.success('Tema guardado exitosamente');
                setTemas(response.data.temas);
            } else {
                toast.error('Error al guardar el tema: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error al enviar los datos:', error);
            toast.error('Error al guardar el tema');
        }
    };

     
    const saveTema = async (tema, colores) => {
        const configuracion = {
            tema: tema
        };

        try {
            const response = await fetch(`${baseURL}/configuracionesPost.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(configuracion)
            });

            const data = await response.json();

            if (data.success) {
                setColors(colores); 
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };
    const eliminarTema = (idTema) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/temaDelete.php?idTema=${idTema}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarTemas();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };
    return (
        <div className='containerGrid' >
            <Header />
            <section className='containerSection'>
                <HeaderDash />
                <div className='container'>
                    <ToastContainer />
                    <h5>Colores</h5>
                    <div className="wrapper">



                        <div className="aside aside-2">
                            <iframe ref={iframeRef} src='/' width={'70%'} height={'800'}></iframe>
                        </div>
                        <div className="aside aside-1  ">
                            <ul>
                                {/* Utiliza map para recorrer cada tema y renderizar un elemento <li> por cada uno */}
                                {temas.map(tema => (
                                    <fieldset key={tema.id}>
                                        <legend> <label>{tema.nombre}  
                                            <input type="radio" value={tema.id} defaultChecked={(tema.id == themaActivo) ? true:false} checked={(tema.id == temaSeleccionado) ? true:false}name="selecion_tema" onClick={() => {
                                                setTemaSeleccionado(tema.id);
                                            }} /></label>
                                        </legend>

                                         

                                        <table className='table-color'>
                                            <tbody>
                                                <tr>


                                                    {
                                                        Object.keys(JSON.parse(tema.json)).map((color, index) => {

                                                            return (
                                                                <td
                                                                    key={index}
                                                                    className="color-cell"
                                                                    style={{ backgroundColor: `${JSON.parse(tema.json)[color]}`, color: `${JSON.parse(tema.json)[color]}` }}
                                                                >_</td>
                                                            )
                                                        })}
                                                </tr>
                                            </tbody>
                                        </table> <button className='btnAction' onClick={() => {
                                            setColors(JSON.parse(tema.json));
                                        }}>Preview</button>
                                         
                                        
                                        <sup>{(tema.id == themaActivo) ? 'actual':(
                                            <button className='btnNormal' onClick={() => {
                                                tema.nombre !== 'Default' ? eliminarTema( tema.id ):toast.error('No puedes eliminar el tema default');
                                            }}>Eliminar</button>

                                        )}</sup>
                                    </fieldset>
                                ))}
                            </ul> 
                            <br />
                            <p> 
                                { 
                                    temaSeleccionado > 0 ? (<button className="btnAction" onClick={() => {
                                        saveTema(temaSeleccionado, colors);
                                    }}>Cambiar tema</button>):('')
                                }</p>

                                <p><span className="form-item">
                                <button onClick={resetColors} className="btnNormal">Volver tema actual</button>
                            </span></p>
                        </div>


                        <div className="aside aside-3"  >
                            <button type="button" onClick={()=>{ setAvanzado(!avanzado)}} className="btnNormal" > Avanzado </button>
                            <div className="controles" style={{ display: avanzado? 'block':'none'  }}>

                                <span className="form-item">
                                    <input type="color" value={colors.color1} id="color1" onChange={handleColorChange} />

                                    <label htmlFor="color1"> Color primario
                                    </label>
                                </span>

                                <span className="form-item">
                                    <input type="color" value={colors.color2} id="color2" onChange={handleColorChange} />

                                    <label htmlFor="color2"> Color segundario
                                    </label>
                                </span>



                                <span className="form-item">
                                    <input type="color" value={colors.bgColor} id="bgColor" onChange={handleColorChange} />

                                    <label htmlFor="bgColor">Color de fondo:
                                    </label>
                                </span>
                                <span className="form-item">
                                    <input type="color" value={colors.textColor2} id="textColor2" onChange={handleColorChange} />

                                    <label htmlFor="textColor2">  Texto sobre fondo
                                    </label>
                                </span>
                                <span className="form-item">

                                    <input type="color" value={colors.textColor} id="textColor" onChange={handleColorChange} />

                                    <label htmlFor="textColor">
                                        Titulo categorías
                                    </label>
                                </span>


                                <span className="form-item">
                                    <input type="color" value={colors.bgDestacado} id="bgDestacado" onChange={handleColorChange} />

                                    <label htmlFor="bgDestacado"> Fondo Precio destacado
                                    </label>
                                </span>

                                <span className="form-item">
                                    <input type="color" value={colors.textDestacado} id="textDestacado" onChange={handleColorChange} />

                                    <label htmlFor="textDestacado"> Precio destacado
                                    </label>
                                </span>

                                <span className="form-item">
                                    <input type="color" value={colors.textTarjetaProductos} id="textTarjetaProductos" onChange={handleColorChange} />

                                    <label htmlFor="textTarjetaProductos"> Texto tarjeta de producto
                                    </label>
                                </span>



                                <span className="form-item">
                                    <input type="color" value={colors.textFooter} id="textFooter" onChange={handleColorChange} />

                                    <label htmlFor="textFooter"> Texto pie de pagina
                                    </label>
                                </span>


                                <span className="form-item">
                                    <input type="color" value={colors.color3} id="color3" onChange={handleColorChange} />

                                    <label htmlFor="color3">Color destacado
                                    </label>
                                </span>


                                <span className="form-item">
                                    <input type="color" value={colors.textColor5} id="textColor5" onChange={handleColorChange} />

                                    <label htmlFor="textColor5">Color texto mas vendido
                                    </label>
                                </span>


                                <span className="form-item">
                                    <input type="color" value={colors.gris} id="gris" onChange={handleColorChange} />

                                    <label htmlFor="gris"> Texto gris
                                    </label>
                                </span>



                                <span className="form-item">
                                    <input type="color" value={colors.colorNavegacion} id="colorNavegacion" onChange={handleColorChange} />

                                    <label htmlFor="colorNavegacion"> Barras de navegacion
                                    </label>
                                </span>

                                <span className="form-item">
                                    <input type="color" value={colors.input} id="input" onChange={handleColorChange} />

                                    <label htmlFor="input"> Inputs
                                    </label>
                                </span>

                                <span className="form-item">
                                    <input type="color" value={colors.loading} id="loading" onChange={handleColorChange} />

                                    <label htmlFor="loading">   Loading
                                    </label>
                                </span>
                                <span className="form-item">
                                    <input type="color" value={colors.colorTextoBtns} id="colorTextoBtns" onChange={handleColorChange} />

                                    <label htmlFor="colorTextoBtns">   color Texto Botenes
                                    </label>
                                </span>
                            <hr />
                            <span className="form-item">

                                <input
                                    type="text"
                                    value={themeName}
                                    name="nombretema"
                                    onChange={(e) => setThemeName(e.target.value)}
                                    placeholder="Nombre del tema"
                                />
                                <label htmlFor="nombretema">   Nombre para guardar tema
                                </label>
                            </span>

                            <span className="form-item">

                                <button onClick={handleSaveColors} className="btnInstall">Guardar Colores</button>
                            </span>

                             

                            </div>
                        </div>
 
                    </div>
                </div>
            </section>
        </div>
    );
}
