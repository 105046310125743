import React from 'react'
import './ProductosLoading.css'
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function ProductosLoading() {
    return (
        <div className='loadingContain'>

            <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={'auto'}
                className='cardsLoading'
            >

                <SwiperSlide className='cardLoading' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading' >

                </SwiperSlide>

                <SwiperSlide className='cardLoading' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading' >

                </SwiperSlide>

            </Swiper>


            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={'auto'}
                className='cardsLoading2'
            >

                <SwiperSlide className='cardLoading2' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading2' >

                </SwiperSlide>

                <SwiperSlide className='cardLoading2' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading2' >

                </SwiperSlide>
                <SwiperSlide className='cardLoading2' >

                </SwiperSlide>

            </Swiper>
        </div>
    )
}
