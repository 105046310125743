import React, { useState, useEffect } from 'react';
import './NewProduct.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function NewProduct() {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [titulo, setTitulo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [masVendido, setMasVendido] = useState('');
    const [precio, setPrecio] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [categorias, setCategoras] = useState([]);
    const [variantes, setVariantes] = useState([]);
    const [precioAnterior, setPrecioAnterior] = useState('');
    const [imagenes, setImagenes] = useState([]);

    const [nuevaVariante, setNuevaVariante] = useState({
        tipo: '',
        nombre: '',
        precio_variante: '',
        precio_suma: ''
    });

    const agregarVariante = () => {
        const nuevasVariantes = { ...variantes };

        if (!nuevasVariantes[nuevaVariante.tipo]) {
            nuevasVariantes[nuevaVariante.tipo] = [];
        }

        nuevasVariantes[nuevaVariante.tipo].push({
            nombre: nuevaVariante.nombre,
            precio_variante: nuevaVariante.precio_variante || 0,
            precio_suma: nuevaVariante.precio_suma || 0
        });

        setVariantes(nuevasVariantes);

        setNuevaVariante({ tipo: '', nombre: '', precio_variante: '', precio_suma: '' });
    };

    const eliminarVariante = (tipo, nombre) => {
        const nuevasVariantes = { ...variantes };

        if (nuevasVariantes[tipo]) {
            nuevasVariantes[tipo] = nuevasVariantes[tipo].filter(variante => variante.nombre !== nombre);

            if (nuevasVariantes[tipo].length === 0) {
                delete nuevasVariantes[tipo];
            }
        }

        setVariantes(nuevasVariantes);
    };



    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };


    const handleImagenChange = (event) => {
        const files = event.target.files;
        const newImagenes = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const previewURL = URL.createObjectURL(file);
            newImagenes.push({ file, previewURL });
        }

        setImagenes(newImagenes);
    };

    const crear = async (e) => {

        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('descripcion', descripcion);
        formData.append('categoria', categoria);
        formData.append('masVendido', masVendido);
        formData.append('precio', precio);
        if (precioAnterior) {
            formData.append('precioAnterior', precioAnterior);
        }else{
            formData.append('precioAnterior', 0);
        }
        if (variantes) {
            formData.append('variantes', JSON.stringify(variantes));
        }


        imagenes.forEach((imagen, index) => {
            formData.append(`imagenes[]`, imagen.file);
        });

        const resetForm = () => {
            setImagenPreview1(null);
            setIsImage1Selected(false);
            setVariantes([]);
        };

        setMensaje('');

        if (
            !descripcion ||
            !titulo ||
            !categoria ||
            !masVendido ||
            !precio
        ) {
            toast.error('Por favor, complete todos los campos correctamente.');
            return;
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/productosPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                resetForm();
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
                console.log(data.error);

            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');

        }
    };


    const handleCategoriaChange = (e) => {
        setCategoria(e.target.value);
    };
    const handleMasVendidoChange = (e) => {
        setMasVendido(e.target.value);
    };

    useEffect(() => {
        cargarCategoria();

    }, []);


    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategoras(data.categorias || []);
                console.log(data.categorias)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>  +</span> Agregar
            </button>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBack'>
                            <h4>Agregar Producto</h4>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form id="crearForm">

                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Título</legend>
                                    <input
                                        type="text"
                                        id="titulo"
                                        name="titulo"
                                        required
                                        value={titulo}
                                        onChange={(e) => setTitulo(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Precio</legend>
                                    <input
                                        type="number"
                                        id="precio"
                                        name="precio"
                                        min="0"
                                        required
                                        value={precio}
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset id='descripcion'>
                                    <legend>Descripción</legend>
                                    <textarea
                                        id="descripcion"
                                        name="descripcion"
                                        required
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        placeholder="Descripción"
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Categoría</legend>
                                    <select
                                        id="categoria"
                                        name="categoria"
                                        value={categoria}
                                        onChange={handleCategoriaChange}
                                    >
                                        <option value="">Selecciona una categoría</option>
                                        {
                                            categorias.map(item => (
                                                <option value={item?.categoria}>{item?.categoria}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Más vendido</legend>
                                    <select
                                        id="masVendido"
                                        name="masVendido"
                                        value={masVendido}
                                        onChange={handleMasVendidoChange}
                                    >
                                        <option value="">Selecciona opcion</option>
                                        <option value="si">Si</option>
                                        <option value="no">No</option>

                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Precio anterior</legend>
                                    <input
                                        type="number"
                                        id="precioAnterior"
                                        name="precioAnterior"
                                        min="0"
                                        required
                                        value={precioAnterior}
                                        onChange={(e) => setPrecioAnterior(e.target.value)}
                                    />
                                </fieldset>
                                <div className='flexGrap'>
                                    <legend>Variables</legend>

                                    <div className='table-container'>
                                        <table className='table table-border'>
                                            <thead>
                                                <tr>
                                                    <th> Tipo </th>
                                                    <th> nombre </th>
                                                    <th> $ Nuevo Precio </th>
                                                    <th> $ Suma al precio </th>
                                                    <th> Acciones </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Object.keys(variantes).map((tipo, tipoIndex) => (
                                                    variantes[tipo].map((variante, varianteIndex) => (
                                                        <tr key={`${tipoIndex}-${varianteIndex}`}>
                                                            <td>{tipo}</td>
                                                            <td>{variante.nombre}</td>
                                                            <td>{variante.precio_variante}</td>
                                                            <td>{variante.precio_suma}</td>
                                                            <td> <button className="remove" onClick={() => eliminarVariante(tipo, variante.nombre)}>
                                                                Eliminar <FontAwesomeIcon icon={faTrash} />
                                                            </button>  </td>
                                                        </tr>
                                                    ))
                                                ))}
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={nuevaVariante.tipo}
                                                            onChange={(e) => setNuevaVariante({ ...nuevaVariante, tipo: e.target.value })}
                                                            placeholder="Tipo"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={nuevaVariante.nombre}
                                                            onChange={(e) => setNuevaVariante({ ...nuevaVariante, nombre: e.target.value })}
                                                            placeholder="Nombre"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={nuevaVariante.precio_variante}
                                                            onChange={(e) => setNuevaVariante({ ...nuevaVariante, precio_variante: e.target.value })}
                                                            placeholder="$ Nuevo Precio"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={nuevaVariante.precio_suma}
                                                            onChange={(e) => setNuevaVariante({ ...nuevaVariante, precio_suma: e.target.value })}
                                                            placeholder="$ Suma al Precio"
                                                        />
                                                    </td>
                                                    <td>
                                                        <button className="add" onClick={(e) => {
                                                            e.preventDefault();
                                                            agregarVariante()
                                                        }}>
                                                            Agregar <FontAwesomeIcon icon={faPlus} />
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>


                                    </div>
                                </div>





                                <fieldset>
                                    <legend>Imágenes</legend>
                                    <input
                                        type="file"
                                        id="imagenes"
                                        name="imagenes"
                                        accept="image/*"
                                        multiple // Permitir selección múltiple
                                        onChange={handleImagenChange}
                                        required
                                    />
                                </fieldset>

                            </div>


                            {imagenes.length > 0 && (
                                <div className='previevCategori'>
                                    {imagenes.map((imagen, index) => (
                                        <img key={index} src={imagen.previewURL} alt={`Vista previa ${index + 1}`} />
                                    ))}
                                </div>
                            )}



                            {mensaje ? (
                                <button type="button" className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type="button" onClick={crear} className='btnSave'>
                                    Agregar
                                </button>
                            )}


                        </form>
                    </div>
                </div>
            )}

        </div>
    );
}

