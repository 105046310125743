import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import './Detail.css'
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faStar, faHeart } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor, useNavigate, useLocation } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'swiper/swiper-bundle.css';
import baseURL from '../url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DetailLoading from "../DetailLoading/DetailLoading";
import { formatCurrencyCLP } from "../helpers";

export default function Detail() {
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const { idProducto } = useParams();
    const [whatsappMsj, setWhatsappMsj] = useState(false);
    const [producto, setProducto] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [cantidad, setCantidad] = useState(1);
    const [productos, setProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactos, setContactos] = useState([]);
    const [favoritos, setFavoritos] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [variantesSeleccionadas, setVariantesSeleccionadas] = useState({});
    const [variantes, setVariantes] = useState([]);
    const [obligatorio, setObligatorio] = useState(false);
    const [obligatorioLista, setObligatorioLista] = useState({});
    const [nuevoPrecio, setNuevoPrecio] = useState(0);
    const [resetCheck, setResetCheckbox] = useState(false);
    useEffect(() => {
        cargarProductos();
        cargarContacto();
        cargarFavoritos();
    }, []);



    const [carrito, setCarrito] = useState(false);

    useEffect(() => {
        // Obtener configuración inicial
        const obtenerConfiguracion = async () => {
            try {
                const response = await fetch(`${baseURL}/configuracionesGet.php`);
                const data = await response.json();

                if (data) {
                    setCarrito((data.carrito == '1' ? true : false));
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error al obtener la configuración. Por favor, inténtelo de nuevo.');
            }
        };

        obtenerConfiguracion();
    }, []);


    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
                console.log(data.productos)
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error)
                setLoading(true);
            });
    };


    const cargarFavoritos = () => {
        const storedFavoritos = JSON.parse(localStorage.getItem('favoritos')) || [];
        setFavoritos(storedFavoritos);
    };
    const obtenerVariantes = (item) => {
        let variantes;
        try {
            variantes = JSON.parse(item.variantes);
        } catch (e) {
            console.error("Error parsing 'variantes':", e);
            return {};
        }

        if (variantes && variantes.tipos) {
            Object.keys(variantes.tipos).forEach(tipo => {
                if (!Array.isArray(variantes.tipos[tipo])) {
                    return;
                }

                variantes.tipos[tipo] = {
                    multiple: false,
                    variantes: variantes.tipos[tipo]
                };
            });
            return variantes.tipos;
        }

        return {};
    };
    useEffect(() => {
        const product = productos.find((e) => e.idProducto === parseInt(idProducto));
        setProducto(product);
        setVariantes(obtenerVariantes(product));
    }, [idProducto, productos]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    function handleCompartirClick() {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Echa un vistazo a este producto',
                url: window.location.href,
            })
                .then(() => console.log('Contenido compartido correctamente'))
                .catch((error) => console.error('Error al compartir:', error));
        } else {
            console.error('La API de compartir no está disponible en este navegador.');
        }
    }






    const addToCart = () => {
        if (producto) {
            const cart = JSON.parse(localStorage.getItem('cart')) || [];
            const existingItemIndex = cart.findIndex(item =>
                item.idProducto === producto.idProducto
            );

            cart.push({ idProducto: producto.idProducto, variante: [{ ...variantesSeleccionadas, cantidad, nuevoPrecio }], cantidad });


            localStorage.setItem('cart', JSON.stringify(cart));

            setVariantes({});
            setVariantesSeleccionadas({});
            // Agregamos la llamada a cargarProductos para actualizar la lista de productos en Products
            cargarProductos();
            toast.success('Producto agregado');
            setTimeout(() => {
                navigate(-1); // Vuelve a la página anterior después de 2 segundos
            }, 2000);

        }
    };

    const handleCheckboxChange = (tipo, nombre, multiple, isObligatorio = false, tipoIndex, precio_variante = 0, precio_suma = 0) => {

        if (obligatorioLista.length > 0) {
            const listaObligatorios = obligatorioLista;
            listaObligatorios[tipoIndex] = !listaObligatorios[tipoIndex];
            setObligatorioLista(listaObligatorios);
            setObligatorio(obligatorioLista.includes(true));
            if (!obligatorioLista.includes(true)) {
                setObligatorioLista({});
            }

        }

        if (precio_variante > 0) {
            console.log(precio_variante)
            setNuevoPrecio(precio_variante);
        }
        setVariantesSeleccionadas((prevSelectedOptions) => {
            if (multiple) {
                const updatedOptions = prevSelectedOptions[tipo] || [];
                if (updatedOptions.includes(nombre)) {


                    return {
                        ...prevSelectedOptions,
                        [tipo]: updatedOptions.filter(option => option !== nombre),
                    };
                } else {
                    return {
                        ...prevSelectedOptions,
                        [tipo]: [...updatedOptions, nombre],
                    };
                }
            } else {

                return {
                    ...prevSelectedOptions,
                    [tipo]: [nombre],
                };
            }
        });
    }
    const incrementCantidad = () => {
        setCantidad(cantidad + 1);
    };

    const decrementCantidad = () => {
        if (cantidad > 1) {
            setCantidad(cantidad - 1);
        }
    };


    const agregarAFavoritos = (idProducto) => {
        const favList = [...favoritos];
        const index = favList.indexOf(idProducto);
        if (index === -1) {
            // Si el producto no está en favoritos, lo agregamos
            favList.push(idProducto);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
            console.log('Producto agregado a favoritos');

        } else {
            // Si el producto está en favoritos, lo eliminamos
            favList.splice(index, 1);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
            console.log('Producto eliminado de favoritos');
        }
    };


    // carrito------------------------------------------------------
    const [cartItems, setCartItems] = useState([]);
    const [isFocused, setIsFocused] = useState(false);


    useEffect(() => {


        const fetchCartItems = async () => {
            const cart = JSON.parse(localStorage.getItem('cart')) || [];
            const promises = cart.map(async (cartItem) => {
                const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);
                return {
                    ...producto,
                    cantidad: cartItem.cantidad,
                    item: cartItem.item,
                };
            });

            Promise.all(promises)
                .then((items) => {
                    setCartItems(items);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error al obtener detalles del carrito:', error);
                    setLoading(false);
                });
        };

        fetchCartItems();
    }, [productos, isFocused]);


    const obtenerImagenes = (item) => {
        let imagenes;
        try {
            imagenes = JSON.parse(item.imagenes);
        } catch (e) {
            console.error("Error parsing 'imagenes':", e);
            return [];
        }

        if (imagenes && imagenes.urls && imagenes.urls.length > 0) {
            return imagenes.urls;
        }
        return [];
    };






    if (!producto) {
        return <DetailLoading />;
    }
    const resetCheckboxChange = () => {
        setVariantesSeleccionadas({});
        setNuevoPrecio(0);


    }
    return (


        <div className="detail">

            <ToastContainer />
            <div className="deFlexDetail">
                <Anchor to={`/`}><button className="back" > <FontAwesomeIcon icon={faArrowLeft} /> </button></Anchor>

                <div className="deFLexIcon">
                    <button onClick={() => agregarAFavoritos(producto.idProducto)} className={favoritos.includes(producto.idProducto) ? ' favoritos-btn  likeColor' : 'favoritos-btn unlike'}>


                        <FontAwesomeIcon icon={faHeart} />
                    </button>
                </div>


            </div>
            <div className="detail-contain">
                {obtenerImagenes(producto).length > 0 ? (
                    <SwiperSlide id={"swiperDetail"} >
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            effect={'coverflow'}
                            grabCursor={true}
                            loop={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                            navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                            autoplay={{ delay: 3000 }}
                            pagination={{ clickable: true }}
                            onSwiper={(swiper) => {
                                console.log(swiper);
                                swiperRef.current = swiper;
                            }}
                            id='swiper_container_Imgs'
                        >

                            {obtenerImagenes(producto).map((url, index) => (
                                <SwiperSlide id='SwiperSlide-scroll-img' key={index}>
                                    <img src={url} alt={`Imagen ${index + 1}`} />
                                </SwiperSlide>
                            ))}



                        </Swiper>
                    </SwiperSlide>) :
                    (

                        <div style={{ paddingTop: '6rem' }}></div>
                    )}
                <div className="textDetail">
                    <h2 className="title">{producto.titulo} <sup className={favoritos.includes(producto.idProducto) ? 'textLike' : ''}>
                        {favoritos.includes(producto.idProducto) ? 'Te gusta' : ''} </sup></h2>
                    <hr />
                    <h4>{producto.categoria}</h4>

                    <div className='deFLexPrice'>
                        <h5 className="price">
                            {formatCurrencyCLP(producto?.precio)}

                        </h5>
                        {
                            producto?.stock === 0 ?? <button className="btn">No disponible</button>
                        }
                         


                        {
                            (producto?.precioAnterior > 0 && producto?.precioAnterior !== undefined) && (
                                <h5 className='precioTachadoDetail'> {formatCurrencyCLP(producto?.precioAnterior)}</h5>
                            )
                        }

                    </div>
                    <p>{producto.descripcion}</p>
                    <div className='itemsDetail'>
                        {Object.keys(variantes).map((tipo, tipoIndex) => {
                            let number = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                            const uniqueId = `switch-${number}`;
                            const isMultiple = variantes[tipo].multiple;
                            const isObligatorio = variantes[tipo].obligatorio;
                            return (
                                <fieldset key={tipoIndex}>
                                    <legend>{tipo}</legend>



                                    {variantes[tipo].variantes && variantes[tipo].variantes.map((variante, varianteIndex) => (
                                        <div key={varianteIndex}>
                                            <label htmlFor={uniqueId + variante.nombre}><input
                                                type={isMultiple ? "checkbox" : "radio"}
                                                name={tipo}
                                                onChange={() => handleCheckboxChange(tipo, variante.nombre, isMultiple, isObligatorio, tipoIndex, variante.precio_variante, variante.precio_suma)}
                                                id={uniqueId + variante.nombre}
                                                checked={variantesSeleccionadas[tipo] && variantesSeleccionadas[tipo].includes(variante.nombre)}
                                            />
                                                <span>{variante.nombre}  </span>
                                                {(variante.precio_variante > 0 ? formatCurrencyCLP(variante.precio_variante) : ' ')}
                                                {(variante.precio_suma > 0 ? '+' + formatCurrencyCLP(variante.precio_suma) : ' ')}
                                            </label>
                                        </div>
                                    ))}

                                    <button className="btnReset" onClick={() => resetCheckboxChange()}>X</button>
                                </fieldset>
                            );
                        })}
                    </div>
                </div>
                <div className='deFlexGoTocart'>

                    {

                        carrito ? (
                        producto?.precio && producto?.stock !== 0 ? (
                            <>

                                <div className='deFlexCart'>
                                    <button onClick={decrementCantidad}>-</button>
                                    <span>{cantidad}</span>
                                    <button onClick={incrementCantidad}>+</button>
                                </div>
                                <button onClick={addToCart} className={obligatorio ? 'btn-disabled' : 'btn'} disabled={obligatorio}>
                                    Agregar  (   {`${formatCurrencyCLP((nuevoPrecio > 0 ? nuevoPrecio : producto?.precio) * cantidad)}`} )</button>
                            </>

                        ) : (<button className="btn">No disponible</button>)

                    ):('')
                    }

                </div>

            </div>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                center
                classNames={{
                    modal: 'custom-modal',
                }}
            >
                <img src={modalImage} alt={producto.titulo} />
            </Modal>


        </div >

    )
}





