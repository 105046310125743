import React, { useState, useEffect } from "react";
import "./InputSearchs.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import baseURL from '../url'; 
import useSearchStore from "../searchStore";
import { formatCurrencyCLP } from '../helpers';
import useProductsStore from '../productsStore';

export default function InputSearchs({ searchTerm, setSearchTerm = () => { }, setProductoSeleccionado = () => { }, setModalIsOpenProducto = () => { } }) {
    const search = useSearchStore((state) => state.search);
    const updateSearch = useSearchStore((state) => state.updateSearch);
    const [filteredProducto, setFilteredProducto] = useState([]);   
    const [showResults, setShowResults] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const productsGlobal = useProductsStore((state) => state.productsSet);

    const [productos, setProductos] = useState([]);
    useEffect(() => { cargarProductos(); }, [baseURL, productsGlobal]);

    const cargarProductos = () => {

                // Filtramos los productos que tienen estado true
                const productosFiltrados = (productsGlobal || []).filter(producto => producto.estado === 1);

                // Guardamos los productos filtrados en el estado
                setProductos(productosFiltrados); 
    };
    const handleSearch = (event) => {
        const searchTermData = event.target.value;
        setSearchTerm(searchTermData);
        const results = productos.filter((producto) => {
            return (
                producto.titulo.toLowerCase().includes(searchTermData.toLowerCase()) ||
                producto.categoria.toLowerCase().includes(searchTermData.toLowerCase()) ||
                producto.descripcion.toLowerCase().includes(searchTermData.toLowerCase())
            );
        });
        setFilteredProducto(results);
        setShowResults(searchTermData !== "");
        setNoResults(searchTermData !== "" && results.length === 0);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {

        setModalIsOpen(false);
        setIsClosing(true);
    };


    const obtenerImagen = (item) => {
        try {
            const imagenes = JSON.parse(item.imagenes);
            if (imagenes.urls && imagenes.urls.length > 0) {
                return imagenes.urls[0];
            }
        } catch (e) {
            console.error("Error al parsear las imágenes:", e);
        }
        return null;
    };

    // funcion que guarda en storage local el valor de la busqueda
    const guardarBusquedaEnStorage = (item) => {
        updateSearch(item);

        // guardar array del item
        localStorage.setItem('busqueda', JSON.stringify(item));
        
    }


    return (
        <div className="fondo-input">
            <div className="search-container">
                 <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={openModal} />  
              
                <Modal isOpen={modalIsOpen}
                 onRequestClose={closeModal} 
                 
                ariaHideApp={false}
                 className={`modalInput modalSearch ${isClosing ? 'modalSearch-close' : ''}`}
                    overlayClassName="overlayInput">
                    <fieldset className="inputSearch" >
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="input"
                        />
                        <FontAwesomeIcon icon={faClose} className="close-icon" onClick={closeModal} />
                    </fieldset>
                    {showResults && (
                        <div className="modalSearch">
                            {filteredProducto.map((item) => (
                                <div key={item.idProducto} className="productCard">

                                    <Link to={`#`} onClick={() => {
                                        guardarBusquedaEnStorage(item);  
                                    }
                                    }>
                                        {
                                            obtenerImagen(item) !== null ? (
                                            <><img src={obtenerImagen(item)} alt="" /> 
                                            <div className="cardText">
                                                <samll>{item.categoria}</samll>
                                                <h3> {item.titulo}</h3> 

                                                        <span>{item.descripcion}</span>
                                                        <br /> 
                                                        

                                                        <h3>{formatCurrencyCLP(item.precio)}</h3>
                                                        <br />   </div></>)
                                                : (
                                                    <div className="cardText">
                                                    <samll>{item.categoria}</samll>
                                                    <h3> {item.titulo}</h3>
                                                        <br />

                                                        <span>{item.descripcion}</span>
                                                        <br /> 
                                                        

                                                        <h3>{formatCurrencyCLP(item.precio)}</h3> 
                                                    </div>
                                                )
                                        }

                                    </Link>
                                </div>
                            ))}
                            {noResults && <p>No se encontraron resultados.</p>}
                        </div>
                    )}
                </Modal>

                
            </div>
        </div>
    );
}
