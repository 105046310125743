import {create} from 'zustand';

// Crear la store
const useSearchStore = create((set) => ({
    search: JSON.parse(localStorage.getItem('search')) || false,
    updateSearch: (newSearch) => {
        localStorage.setItem('search', JSON.stringify(newSearch));
        set({ search: newSearch });
    },
}));

export default useSearchStore;
