import React, { useEffect, useState, useRef } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../url';
import './Banners.css';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import useBannersStore from '../bannersStore';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/swiper-bundle.css'; 

export default function Banners() {
    const banners = useBannersStore(state => state.bannersData);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const swiperRef = useRef(null);

    useEffect(() => {
        cargarBanners();
    }, [banners]);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current?.update();
        }
    }, [images]);

    const cargarBanners = () => { 
        if(banners) {
                const bannerImages = banners.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false); 
        }
    };

    // Si no hay imágenes y no está cargando, no muestra nada.
    if (!loading && images.length === 0) {
        return null;
    }

    return (
        <div className='BannerContain'>
            {loading ? (
                <div className='loadingBanner'>
                    {/* Aquí puedes agregar un spinner o mensaje de carga si es necesario */}
                </div>
            ) : (
                <Swiper
                    effect={'coverflow'} 
                    style=  {{
                        "--swiper-pagination-color": "var(--color1)",
                        "--swiper-pagination-bullet-inactive-color": "var(--textColor)",
                        "--swiper-pagination-bullet-inactive-opacity": ".5",
                        "--swiper-pagination-bullet-size": "16px",
                        "--swiper-pagination-bullet-horizontal-gap": "3px"
                      }}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    grabCursor={true}
                    loop={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    autoplay={{ delay: 3000 }}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    id='swiper_container'
                >
                    {images.map((image, index) => (
                        <SwiperSlide id='SwiperSlide-scroll' key={index}>
                            <img src={image} alt={`imagen-${index}`} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
}
