import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faBars } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'; 
import 'jspdf-autotable';
import baseURL from '../../url';
import NewCategoria from '../NewCategoria/NewCategoria';

export default function CategoriasData() {
    const [categorias, setCategoras] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevaCategoria, setNuevaCategoria] = useState('');
    const [nuevaDescripcion, setNuevaDescripcion] = useState('');
    const [nuevaTipo, setNuevaTipo] = useState('');
    const [nuevaOrden, setNuevaOrden] = useState('');
    const [categoria, setCategoria] = useState({});
    const [selectedSection, setSelectedSection] = useState('texto');
    const [draggedItem, setDraggedItem] = useState(null);

    useEffect(() => {
        cargarCategoria();
    }, []);

    const cargarCategoria = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                // Sort categories by orden
                const sortedCategorias = data.categorias 
                    ? data.categorias.sort((a, b) => a.orden - b.orden) 
                    : [];
                setCategoras(sortedCategorias);
            })
            .catch(error => {
                console.error('Error al cargar categorías:', error);
                toast.error('No se pudieron cargar las categorías');
            });
    };

    const eliminarCategoria = (idCategoria) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/categoriaDelete.php?idCategoria=${idCategoria}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire('¡Eliminado!', data.mensaje, 'success');
                        cargarCategoria();
                    })
                    .catch(error => {
                        console.error('Error al eliminar categoría:', error);
                        toast.error('No se pudo eliminar la categoría');
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setCategoria(item);
        setNuevaCategoria(item.categoria);
        setNuevaDescripcion(item.descripcion);
        setNuevaTipo(item.tipo);
        setNuevaOrden(item.orden);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const handleUpdateText = (idCategoria) => {
        const payload = {
            categoria: nuevaCategoria !== '' ? nuevaCategoria : categoria.categoria,
            descripcion: nuevaDescripcion !== '' ? nuevaDescripcion : categoria.descripcion,
            tipo: nuevaTipo !== '' ? nuevaTipo : categoria.tipo,
            orden: nuevaOrden !== '' ? nuevaOrden : categoria.orden 
        };

        fetch(`${baseURL}/categoriaPut.php?idCategoria=${idCategoria}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire('Error!', data.error, 'error');
                } else {
                    Swal.fire('Editado!', data.mensaje, 'success');
                    cargarCategoria();
                    cerrarModal();
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('No se pudo actualizar la categoría');
            });
    };

    const handleDragStart = (e, item) => {
        setDraggedItem(item);
        e.dataTransfer.effectAllowed = 'move';
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    };

    const handleDrop = (e, targetItem) => {
        e.preventDefault();
        if (!draggedItem || draggedItem === targetItem) return;

        const updatedCategorias = [...categorias];
        const draggedIndex = updatedCategorias.findIndex(
            (cat) => cat.idCategoria === draggedItem.idCategoria
        );
        const targetIndex = updatedCategorias.findIndex(
            (cat) => cat.idCategoria === targetItem.idCategoria
        );

        // Swap orden values
        const tempOrden = updatedCategorias[draggedIndex].orden;
        updatedCategorias[draggedIndex].orden = updatedCategorias[targetIndex].orden;
        updatedCategorias[targetIndex].orden = tempOrden;

        // Swap items in the array
        [updatedCategorias[draggedIndex], updatedCategorias[targetIndex]] = 
        [updatedCategorias[targetIndex], updatedCategorias[draggedIndex]];

        setCategoras(updatedCategorias);
        setDraggedItem(null);
    };

    const guardarOrden = () => {
        const ordenUpdates = categorias.map(categoria => ({
            idCategoria: categoria.idCategoria,
            orden: categoria.orden
        }));

        fetch(`${baseURL}/categoriasUpdateOrden.php`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ordenUpdates),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    Swal.fire('Error!', data.error, 'error');
                } else {
                    Swal.fire('Orden Guardado!', data.mensaje, 'success');
                    cargarCategoria();
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('No se pudo guardar el orden');
            });
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };

    return (
        <div>
            <ToastContainer />
            <NewCategoria />

            {/* Modal de Edición */}
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Categoria</legend>
                                    <input
                                        type="text"
                                        value={nuevaCategoria !== '' ? nuevaCategoria : categoria.categoria}
                                        onChange={(e) => setNuevaCategoria(e.target.value)}
                                    />
                                </fieldset>
                                
                                <fieldset>
                                    <legend>Descripción</legend> 
                                    <textarea
                                        type="text"
                                        value={nuevaDescripcion !== '' ? nuevaDescripcion : categoria.descripcion}
                                        onChange={(e) => setNuevaDescripcion(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Tipo</legend>
                                    <select
                                        value={nuevaTipo !== '' ? nuevaTipo : categoria.tipo}
                                        onChange={(e) => setNuevaTipo(e.target.value)}
                                    >
                                        <option value="">Seleccionar Tipo</option>
                                        <option value="Cocina">Cocina</option>
                                        <option value="Barra">Barra</option>
                                    </select>
                                </fieldset> 
                            </div>

                            <button className='btnPost' onClick={() => handleUpdateText(categoria.idCategoria)}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Botón para guardar el orden */}
            <div className='btn'>
                <button onClick={guardarOrden} className=''>
                    <FontAwesomeIcon icon={faSync} /> Guardar Orden
                </button>
            </div>

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Orden</th>
                            <th>Categoria</th>
                            <th>Descripción</th>
                            <th>Tipo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categorias.map((item, index) => (
                            <tr 
                                key={item.idCategoria}
                                draggable
                                onDragStart={(e) => handleDragStart(e, item)}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, item)}
                            >
                                <td>
                                    <FontAwesomeIcon 
                                        icon={faBars} 
                                        style={{cursor: 'move'}} 
                                        title="Arrastrar para reordenar" 
                                    />
                                    {item.orden}
                                </td>
                                <td>{item.categoria}</td>
                                <td>{item.descripcion}</td>
                                <td>{item.tipo}</td>
                                <td>
                                    <button 
                                        className='eliminar' 
                                        onClick={() => eliminarCategoria(item.idCategoria)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button 
                                        className='editar' 
                                        onClick={() => abrirModal(item)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}