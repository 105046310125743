import React from 'react' 
import Header from '../Header/Header' 
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'
import BannerEditor from '../../Components/Admin/BannerData/BannerEditor'
export default function Banners() {
    return (
        <div className='containerGrid'>
            <Header />

            <section className='containerSection'>

                <HeaderDash />
                <div className='container'>
                    <BannerEditor />
                </div>
            </section>
        </div>
    )
}
