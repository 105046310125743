import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'; // Importa el registro del SW
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (window.confirm("Hay una nueva versión disponible. ¿Deseas actualizar ahora?")) {
      // Forzar actualización
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
});
reportWebVitals(); 
