import {create} from 'zustand';

// Crear la store
const useBannersStore = create((set) => ({
    bannersData: JSON.parse(localStorage.getItem('bannersGlobal')) || false,
    updateBanners: (newUpdate) => {
        localStorage.setItem('bannersGlobal', JSON.stringify(newUpdate));
        set({ bannersData: newUpdate });
    },
}));

export default useBannersStore;