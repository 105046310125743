import React, { useState, useEffect } from 'react';

export default function ColorUpdater() {
    const [colors, setColors] = useState({});

    useEffect(() => {
        localStorage.removeItem('colors');
        // Función para obtener los colores del localStorage
        const getColors = () => {
            const savedColors = localStorage.getItem('colors');
            if (savedColors) {
                setColors(JSON.parse(savedColors));
            }
        };

        // Obtener los colores iniciales
        getColors();

        // Escuchar cambios en el localStorage
        const handleStorageChange = (e) => {
            if (e.key === 'colors') {
                getColors();
            }
        };

        // Agregar el event listener
        window.addEventListener('storage', handleStorageChange);

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // Aplicar los colores al elemento root
    useEffect(() => {
        const root = document.documentElement;
        Object.entries(colors).forEach(([key, value]) => {
            root.style.setProperty(`--${key}`, value);
        });
    }, [colors]);

    return null; // Este componente no renderiza nada visible
}