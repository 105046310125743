import React, { useState, useEffect } from 'react';
import './BtnWhatsapp.css';
import Modal from 'react-modal';
import baseURL from '../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import useConfigStore from '../configStore';
import useContactoStore from '../contactoStore';

export default function BtnWhatsapp() {
    const contacto = useContactoStore((state) => state.contactoData);
    const config = useConfigStore((state) => state.configData);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [contactos, setContactos] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [noteText, setNoteText] = useState('¡Hola! Quiero consultar celebraciones especiales...');
    const [isWhatsapp, setIsWhatsapp] = useState(false);

    useEffect(() => {
        cargarContacto();

    }, [config]);
    const [isWhatsappShow, setIsWhatsappShow] = useState(false);

    useEffect(() => {

        obtenerConfiguracion();
    }, [contacto]);

        // Obtener configuración inicial
        const obtenerConfiguracion = async () => {
            
                const data = config;

                if (data) {
                    setIsWhatsappShow((data.whatsappBoton == '1' ? true : false));
                }
                
        };

    const cargarContacto = () => { 
                setContactos(contacto || []);
                setIsWhatsapp((contacto[0]?.telefono !== '' ? true : false));
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleWhatsappMessage = () => {
        if (selectedContact || contactos?.length <= 1) {
            const phoneNumber = contactos?.length <= 1 ? contactos[0]?.telefono : selectedContact?.telefono;

            let noteMessage = '';
            if (noteText.trim() !== '') {
                noteMessage += `\n ${noteText}`;
            }

            const message = `${noteMessage}`;

            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

            window.open(whatsappUrl, '_blank');

            setNoteText('');
            closeModal();
        }
    };

    return (
        <> 

        {isWhatsappShow && (
            <span to={`/`} className='plus'>

                <div className='containWpp'>
                    <button className='btnWhatsapp' type={'button'} onClick={openModal}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </button>

                </div>
            </span>
        )}
            


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="modal-wpp"
                overlayClassName="overlay-wpp"
            >
                <div className='containModalWpp'>


                    <div className='headerWpp'>

                        {contactos.length > 1 ? (
                            <span>
                                Selecciona un teléfono
                            </span>
                        ) : (
                            <span>
                                Envíanos un mensaje
                            </span>
                        )}
                        <button onClick={closeModal} className='closeBtn'>
                            X
                        </button>
                    </div>

                    <div className='btnsWpp'>
                        {contactos.length > 1 && (
                            <div className='btnsWpp'>
                                {contactos.map(item => (
                                    <button
                                        key={item.idContacto}
                                        className='btnWpp'
                                        style={{ backgroundColor: selectedContact && selectedContact.idContacto === item.idContacto ? 'green' : '' }}
                                        onClick={() => setSelectedContact(item)}
                                    >
                                        <FontAwesomeIcon icon={faUser} />  {item.nombre}

                                    </button>
                                ))}
                            </div>
                        )}


                    </div>


                    <div className='mensaje'>
                        <p>Hola, ¿en qué podemos ayudarte? 👋</p>
                    </div>


                    <div className='sendWpp'>
                        <textarea
                            placeholder="Envíanos un mensaje"
                            value={noteText}
                            onChange={(e) => setNoteText(e.target.value)}
                        />

                        {contactos.length > 1 ? (
                            selectedContact != null && (
                                <button onClick={handleWhatsappMessage}>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            )
                        ) : (<button onClick={handleWhatsappMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>)
                        }
                    </div>
                </div>
            </Modal>

        </>
    );
}
