import React from 'react'; 
import './Spiner.css';  // Asegúrate de importar el archivo CSS donde definirás los estilos del spinner.

export default function Spiner() {
    return (
        <div className='spinnerContainer'>
            <div className='spinner'> 
                <p>Cargando...</p>
            </div>
        </div>
    );
}
