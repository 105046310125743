import {create} from 'zustand';

// Crear la store
const logoStore = create((set) => ({
    logoData: JSON.parse(localStorage.getItem('logoGlobal')) || false,
    updateLogo: (newUpdate) => {
        localStorage.setItem('logoGlobal', JSON.stringify(newUpdate));
        set({ logoData: newUpdate });
    },
}));

export default logoStore;