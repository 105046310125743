import React, { useState, useRef } from 'react';
import { Stage, Layer, Image, Text, Transformer } from 'react-konva';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Slider } from "../../ui/slider";

const CanvasEditor = () => {
  const [elements, setElements] = useState([]);
  const [selectedId, selectShape] = useState(null);
  const [bgColor, setBgColor] = useState('#ffffff');
  const [bgImage, setBgImage] = useState(null);
  const stageRef = useRef();
  const transformerRef = useRef();

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const addText = () => {
    const newElement = {
      type: 'text',
      id: `text${elements.length + 1}`,
      x: 50,
      y: 50,
      text: 'Nuevo texto',
      fontSize: 20,
      fill: 'black',
      width: 200,
      height: 100,
      rotation: 0,
    };
    setElements([...elements, newElement]);
    selectShape(newElement.id);
  };

  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new window.Image();
      img.src = event.target.result;
      img.onload = () => {
        const newElement = {
          type: 'image',
          id: `image${elements.length + 1}`,
          x: 50,
          y: 50,
          image: img,
          width: 100,
          height: 100,
          rotation: 0,
        };
        setElements([...elements, newElement]);
        selectShape(newElement.id);
      };
    };
    reader.readAsDataURL(file);
  };

  const handleTransform = (index) => (newAttrs) => {
    setElements(
      elements.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            ...newAttrs,
          };
        }
        return el;
      })
    );
  };

  const handleTextEdit = (index, newText) => {
    setElements(
      elements.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            text: newText,
          };
        }
        return el;
      })
    );
  };

  const handleFontChange = (index, newFont) => {
    setElements(
      elements.map((el, i) => {
        if (i === index && el.type === 'text') {
          return {
            ...el,
            fontFamily: newFont,
          };
        }
        return el;
      })
    );
  };

  const handleColorChange = (index, newColor) => {
    setElements(
      elements.map((el, i) => {
        if (i === index && el.type === 'text') {
          return {
            ...el,
            fill: newColor,
          };
        }
        return el;
      })
    );
  };

  const handleSizeChange = (index, width, height) => {
    setElements(
      elements.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            width,
            height,
          };
        }
        return el;
      })
    );
  };

  const handleRotationChange = (index, rotation) => {
    setElements(
      elements.map((el, i) => {
        if (i === index) {
          return {
            ...el,
            rotation,
          };
        }
        return el;
      })
    );
  };

  const editElement = () => {
    const element = elements.find((el) => el.id === selectedId);
    if (element && element.type === 'text') {
      Swal.fire({
        title: 'Editar elemento',
        html:
          '<input id="swal-text" class="swal2-input" placeholder="Texto">' +
          '<select id="swal-font" class="swal2-select">' +
          '<option value="Arial">Arial</option>' +
          '<option value="Helvetica">Helvetica</option>' +
          '<option value="Times New Roman">Times New Roman</option>' +
          '<option value="Courier">Courier</option>' +
          '</select>' +
          '<input id="swal-color" type="color" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
          return {
            text: document.getElementById('swal-text').value,
            font: document.getElementById('swal-font').value,
            color: document.getElementById('swal-color').value
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const index = elements.findIndex((el) => el.id === selectedId);
          handleTextEdit(index, result.value.text);
          handleFontChange(index, result.value.font);
          handleColorChange(index, result.value.color);
        }
      });
    }
  };

  const deleteElement = (id) => {
    setElements(elements.filter(el => el.id !== id));
    if (selectedId === id) {
      selectShape(null);
    }
  };

  return (
    <div className="flex p-4">
      <div className="w-3/4 mr-4">
        <div className="mb-4 space-x-2">
          <Button onClick={addText}>Agregar Texto</Button>
          <label className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Agregar Imagen
            <input type="file" className="hidden" onChange={addImage} accept="image/*" />
          </label>
          <Input
            type="color"
            value={bgColor}
            onChange={(e) => setBgColor(e.target.value)}
            className="w-12 h-12"
          />
          <label className="cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Fondo de Imagen
            <input
              type="file"
              className="hidden"
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                  setBgImage(event.target.result);
                };
                reader.readAsDataURL(file);
              }}
              accept="image/*"
            />
          </label>
        </div>
        <Stage
          width={600}
          height={300}
          ref={stageRef}
          onMouseDown={checkDeselect}
          style={{
            backgroundColor: bgColor,
            backgroundImage: bgImage ? `url(${bgImage})` : 'none',
            backgroundSize: 'cover',
          }}
        >
          <Layer>
            {elements.map((el, i) => {
              if (el.type === 'image') {
                return (
                  <Image
                    key={el.id}
                    {...el}
                    draggable
                    onDragEnd={(e) => {
                      handleTransform(i)({
                        x: e.target.x(),
                        y: e.target.y(),
                      });
                    }}
                    onClick={() => selectShape(el.id)}
                    onTransform={(e) => {
                      const node = e.target;
                      handleTransform(i)({
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * node.scaleX(),
                        height: node.height() * node.scaleY(),
                        rotation: node.rotation(),
                      });
                    }}
                  />
                );
              } else if (el.type === 'text') {
                return (
                  <Text
                    key={el.id}
                    {...el}
                    draggable
                    onDragEnd={(e) => {
                      handleTransform(i)({
                        x: e.target.x(),
                        y: e.target.y(),
                      });
                    }}
                    onClick={() => selectShape(el.id)}
                    onTransform={(e) => {
                      const node = e.target;
                      handleTransform(i)({
                        x: node.x(),
                        y: node.y(),
                        width: node.width() * node.scaleX(),
                        height: node.height() * node.scaleY(),
                        rotation: node.rotation(),
                      });
                    }}
                  />
                );
              }
              return null;
            })}
            {selectedId && (
              <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 5 || newBox.height < 5) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </Layer>
        </Stage>
      </div>
      <div className="w-1/4">
        <h2 className="text-xl font-bold mb-4">Lista de Elementos</h2>
        <ul className="space-y-2">
          {elements.map((el, index) => (
            <li key={el.id} className="bg-gray-100 p-2 rounded">
              <div className="flex justify-between items-center">
                <span>{el.type === 'text' ? el.text : `Imagen ${index + 1}`}</span>
                <Button onClick={() => deleteElement(el.id)} className="bg-red-500 hover:bg-red-700">Eliminar</Button>
              </div>
              <div className="mt-2">
                <label className="block">Ancho:</label>
                <Slider
                  value={[el.width]}
                  onValueChange={(value) => handleSizeChange(index, value[0], el.height)}
                  min={5}
                  max={300}
                  step={1}
                />
              </div>
              <div className="mt-2">
                <label className="block">Alto:</label>
                <Slider
                  value={[el.height]}
                  onValueChange={(value) => handleSizeChange(index, el.width, value[0])}
                  min={5}
                  max={300}
                  step={1}
                />
              </div>
              <div className="mt-2">
                <label className="block">Rotación:</label>
                <Slider
                  value={[el.rotation]}
                  onValueChange={(value) => handleRotationChange(index, value[0])}
                  min={0}
                  max={360}
                  step={1}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CanvasEditor;