import {create} from 'zustand';

// Crear la store
const useCategoriesStore = create((set) => ({
    categoriesSet: JSON.parse(localStorage.getItem('categoriesGlobal')) || false,
    updateCategories: (newUpdate) => {
        // ordenar segun el campo "orden" de menor a mayor 
        localStorage.setItem('categoriesGlobal', JSON.stringify(newUpdate.sort((a, b) => a.orden - b.orden)));
        set({ categoriesSet: newUpdate });
    },
}));

export default useCategoriesStore;