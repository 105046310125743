import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash';
import Logo from '../../Components/logo';
import baseURL from '../../Components/url';
import { ToastContainer, toast } from 'react-toastify';

export default function Configuracion() {
    const [imagen, setImagen] = useState({});
    const [mensaje, setMensaje] = useState('');
    const [whatsappVenta, setWhatsappVenta] = useState(false);
    const [whatsappBoton, setWhatsappBoton] = useState(false);
    const [propinaVenta, setPropinaVenta] = useState(false);
    const [carrito, setCarrito] = useState(false);
    const [bio, setBio] = useState('');

    useEffect(() => {
        // Obtener configuración inicial
        const obtenerConfiguracion = async () => {
            try {
                const response = await fetch(`${baseURL}/configuracionesGet.php`);
                const data = await response.json(); 
                if (data) {
                    setWhatsappVenta((data.whatsappVenta == '1' ? true:false));
                    setWhatsappBoton((data.whatsappBoton == '1' ? true:false));
                    setPropinaVenta((data.propinaVenta == '1' ? true:false));
                    setCarrito((data.carrito == '1' ? true:false));
                    setBio(data.bio)
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error al obtener la configuración. Por favor, inténtelo de nuevo.');
            }
        };

        obtenerConfiguracion();
    }, []);

    const logoUpload = async () => {
        const form = document.getElementById("logo_uploading");
        const formData = new FormData(form);
        const resetForm = () => {
            form.reset();
        };
        setMensaje('');

        if (!formData.get('imagen')) {
            toast.error('Por favor, seleccione una imagen.');
            return;
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/logoPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                resetForm();
                toast.success(data.mensaje);
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    const handleImagenChange = (event) => {
        const files = event.target.files;
        const newImagenes = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const previewURL = URL.createObjectURL(file);
            newImagenes.push({ file, previewURL });
        }

        setImagen(newImagenes);
    };

    const handleconfiguracionesPost = async () => {
        const configuracion = {
            whatsappVenta,
            whatsappBoton,
            propinaVenta,
            carrito,
            bio
        };

        try {
            const response = await fetch(`${baseURL}/configuracionesPost.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(configuracion)
            });

            const data = await response.json();

            if (data.mensaje) {
                toast.success(data.mensaje);
            } else if (data.error) {
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className='containerGrid'>
            <Header />
            <section className='containerSection'>
                <HeaderDash />
                <ToastContainer />
                <div className='container'>
                    <h5>Configuración</h5>
                    <div className="wrapper">
                        <div className="aside aside-2">
                            <form id="logo_uploading">
                                <fieldset>
                                    <legend>Logo</legend>
                                    {imagen[0] ? (
                                        <div className='previevCategori'>
                                            <img src={imagen[0]?.previewURL} alt={`Vista previa `} />
                                        </div>
                                    ) : (
                                        <Logo />
                                    )}
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen logo</legend>
                                    <input
                                        type="file"
                                        id="imagen"
                                        name="imagen"
                                        accept="image/*"
                                        multiple
                                        onChange={handleImagenChange}
                                        required
                                    />
                                </fieldset>
                                {mensaje ? (
                                    <button type="button" className='btnLoading' disabled>
                                        {mensaje}
                                    </button>
                                ) : (
                                    <button type="button" onClick={logoUpload} className='btnPost'>
                                        Agregar
                                    </button>
                                )}
                            </form>
                        </div>
                        <div className="aside aside-1">
                            <fieldset>
                                <legend>Biografía panel</legend>
                                { /* <label htmlFor="bio">Biografía:</label> */ }
                                   <textarea 
                                   id="bio"
                                   name="bio"
                                   value={bio} onChange={(e) => setBio(e.target.value)} /> 

                            </fieldset>
                            <fieldset>
                                <legend>WhatsApp</legend>
                                <label htmlFor="whatsapp_venta">
                                    <input
                                        type="checkbox"
                                        name="whatsapp_venta"
                                        checked={whatsappVenta}
                                        onChange={(e) => setWhatsappVenta(e.target.checked)}
                                    /> Habilitar venta por WhatsApp
                                </label> 

                                
                                <label htmlFor="whatsapp_boton">
                                    <input
                                        type="checkbox"
                                        name="whatsapp_boton"
                                        checked={whatsappBoton}
                                        onChange={(e) => setWhatsappBoton(e.target.checked)}
                                    /> Habilitar el botón de WhatsApp
                                </label>
                            </fieldset>
                            <fieldset>
                                <legend>Carrito</legend>
                                <label htmlFor="carrito">
                                    <input
                                        type="checkbox"
                                        name="carrito"
                                        checked={carrito}
                                        onChange={(e) => setCarrito(e.target.checked)}
                                    /> Habilitar carrito
                                </label>

 
                                <label htmlFor="propina_venta">
                                    <input
                                        type="checkbox"
                                        name="propina_venta"
                                        checked={propinaVenta}
                                        onChange={(e) => setPropinaVenta(e.target.checked)}
                                    /> Habilitar propina sugerida
                                </label>
                            </fieldset>
                            <button type="button" onClick={handleconfiguracionesPost} className='btnPost'>
                                Guardar Configuración
                            </button>
                        </div>
                        <div className="aside aside-3"></div>
                    </div>
                </div>
            </section>
        </div>
    );
}
