import {create} from 'zustand';

// Crear la store
const useContactoStore = create((set) => ({
    contactoData: JSON.parse(localStorage.getItem('contactoGlobal')) || false,
    updateContacto: (newUpdate) => {
        localStorage.setItem('contactoGlobal', JSON.stringify(newUpdate.reverse()));
        set({ contactoData: newUpdate });
    },
}));

export default useContactoStore;