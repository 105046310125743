export const formatCurrencyCLP = (value) => {
    const money = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP', 
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0, // Pesos chilenos no usan decimales
        maximumFractionDigits: 0,
        // espacio entre el número y el símbolo de la moneda
        useGrouping: true,
        // separador de miles (punto en este caso)
        groupingSeparator: '.',
        // separador de decimales (coma en este caso)
        decimalSeparator: ','
        

    }).format(value);

    // foramatea $ x.xxx
    return money.replace('$', '$ ')
};