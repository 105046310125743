import React, { useState, useEffect } from 'react';
import Spiner from '../Components/Admin/Spiner/Spiner';
import { Outlet } from 'react-router-dom';
import Auth from '../Components/Admin/Auth/Auth';
import baseURL from '../Components/url'; 


export default function MainLayout() {
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true); 

    
  useEffect(() => {
    const link = document.createElement('link');
    link.href = '/fontCompany.css?v='+Math.random(10);
    link.rel = 'stylesheet';
    document.head.appendChild(link); 
    // Cleanup function to remove the link when the component is unmounted
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  
    useEffect(() => {
        const link = document.createElement('link');
        link.href = '/tema.css?v='+Math.random(10);
        link.rel = 'stylesheet';
        document.head.appendChild(link); 
        // Cleanup function to remove the link when the component is unmounted
        return () => {
          document.head.removeChild(link);
        };
      }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);

            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <div> 

                {loading ? (
                    <Spiner />
                ) : usuario.idUsuario ? (
                    <>
                        <Outlet />
                    </>
                ) : (
                    <Auth />
                )}
            </div>

        </div>
    );
}
