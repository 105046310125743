import {create} from 'zustand';

// Crear la store
const useProductsStore = create((set) => ({
    productsSet: JSON.parse(localStorage.getItem('productsGlobal')) || false,
    updateProducts: (newUpdate) => {
        localStorage.setItem('productsGlobal', JSON.stringify(newUpdate));
        set({ productsSet: newUpdate });
    },
}));

export default useProductsStore;