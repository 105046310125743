import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './ProductosMain.css'
import baseURL from '../../url';
import { formatCurrencyCLP } from '../../helpers'; 
export default function ProductosMain() {
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        cargarProductos();
    }, []);



    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.reverse().slice(0, 5) || []);
                console.log(data.productos)
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };


    return (


        <div className='table-containerProductos'>
            <div className='deFlexMore'>
                <h3>Ultimos {productos?.length} productos</h3>
                <Anchor to={`/dashboard/productos`} className='logo'>
                    Ver más
                </Anchor>
            </div>
            <table className='table'>
                <thead>
                    <tr> 
                        <th>Titulo</th> 
                        <th>Precio</th>
                        <th>Categoria</th> 
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {productos.map(item => (
                        <tr key={item.idProducto}> 
                            <td>{item.titulo}</td> 
                            <td>
                                { formatCurrencyCLP(item?.precio) }
                            </td>

                            <td>  {`${item?.categoria}`}</td> 
                            <td>{item.estado ? 'activo':'inactivo'}</td>


                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};
