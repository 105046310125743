const getBaseURL = () => {
    const { protocol, hostname, port } = window.location;
    if( hostname !=='localhost'){
      return `${protocol}//${hostname}${port ? `:${port}` : ''}/`;
    }else{
      
      return 'http://localhost/';
    }
    
  };
  
  const baseURL = getBaseURL();
  
  export default baseURL;