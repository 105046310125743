import {create} from 'zustand';

// Crear la store
const useConfigStore = create((set) => ({
    configData: JSON.parse(localStorage.getItem('configGlobal')) || false,
    updateConfig: (newUpdate) => {
        localStorage.setItem('configGlobal', JSON.stringify(newUpdate));
        set({ configData: newUpdate });
    },
}));

export default useConfigStore;